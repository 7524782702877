@import './variables.scss';
@import './bootstrap.scss';
@import './fonts.scss';
@import './scroll.scss';

.task-row {
    height: 80px;
}

.primary-hover {
    cursor: pointer;

    &:hover {
        color: $blue-primary;
    }

    &.disabled {
        color: $grey-secondary;

        &:hover {
            color: $grey-secondary;
            cursor: default;
        }
    }
}

.user-initials {
    font-size: 1.75rem;
    width: 60px;
    height: 60px;
    background-color: $grey-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-wrap .modal-dialog {
    min-width: 600px;
}
