// Colours
$font-default: #2c2d2b;
$scroll-background: #ced4da;
$panel-background: #f8f9fa;
$panel-border: #dee2e6;
$panel-font: #6c757d;
$black-primary: #000000;
$black-secondary: #212529;
$black-tertiary: #63676b;
$side-nav-hover: #343a40;
$red-primary: #eb4455;
$red-secondary: #f9c5ca;
$grey-primary: #64717c;
$grey-secondary: #e9ecef;
$grey-tertiary: #ebebeb;
$grey-font: #adb5bd;
$grey-border: #f8f9fa;
$yellow-primary: #ffbb38;
$yellow-secondary: #ffeac1;
$green-primary: #23dd87;
$green-secondary: #c3f4d9;
$green-tertiary: #199d60;
$black: #000000;
$white: #ffffff;
$blue-primary: #59c1ef;
$blue-secondary: darken($blue-primary, 15);
$blue-tertiary: lighten($blue-primary, 15);
$purple-primary: #6610f2;
$purple-secondary: darken($purple-primary, 15);
$purple-tertiary: lighten($purple-primary, 15);
$blue-chat: #288bf6;
$blue-email: #3e8aaa;
$blue-faded: #dff9fe;
$blue-webchat: #0d6dfc;
$yellow-primary: #ffbb38;
$yellow-secondary: #fff3cd;
$yellow-tertiary: #cc9a06;

:root {
    --red-primary: #eb4455;
    --yellow-primary: #ffbb38;
    --green-primary: #23dd87;
    --blue-primary: #59c1ef;
    --background: #f7f9fa;
    --dark: #000000;
    --purple-primary: #6610f2;
    --webchat-primary: #0d6dfc;
    --email-primary: #3e8aaa;
    --box-shadow-default: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
}
